<template>
    <v-row no-gutters class="ma-0 pa-0" justify="center" style="height: 100%;">
        <v-col cols="12" class="ma-0 pa-0" style="height: 100%;">
            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-alert type="info">You are logged in</v-alert>
                    <!-- <v-card elevation="4" min-height="180" class="mb-5">
                        <v-toolbar dense elevation="0">
                            <v-toolbar-title>You are logged in</v-toolbar-title>
                        </v-toolbar>
                    </v-card> -->
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>
<script>
// import AccountViewList from '@/components/AccountViewList.vue';

export default {
    components: {
        // AccountViewList,
    },
    data: () => ({
        // accountId: null,
        // accountList: [],
    }),
    watch: {
        // $route(value, oldValue) {
        //     if (value.query.accountId !== oldValue.query.accountId) {
        //         this.accountId = value.query.accountId;
        //     }
        // },
    },
    methods: {
        // async loadAccountList() {
        //     try {
        //         const result = await this.$client.account().list();
        //         console.log(`Account.vue init result: ${JSON.stringify(result)}`);
        //         if (result && result.list) {
        //             this.accountList = result.list;
        //         }
        //     } catch (err) {
        //         console.error('loadAccountList failed', err);
        //         const message = err.message ? err.message : null;
        //         this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to load account list', message });
        //     }
        // },
        // onSelectAccount({ accountId }) {
        //     this.$router.push({ name: 'dashboard', query: { accountId } });
        // },
    },
    mounted() {
        // if (this.$route.query.accountId) {
        //     this.accountId = this.$route.query.accountId;
        // } else {
        //     this.loadAccountList();
        // }
    },
};
</script>
